import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Article } from "../../style/article"

const KickStart2021FirstArticle = () => {
  const data = useStaticQuery(graphql`
    query RopeImgQuery {
      headerImg: file(base: { eq: "ropes.jpg" }) {
        publicURL
      }
    }
  `)
  return (
    <Layout>
      <Article>
        <SEO
          title="Calisthenics: The Secret At Home Workout Technique to Kick-start your 2021"
          description="Learn what calisthenics is all about and how your can benefit from  engaging in regular physical exercise. With some easy to start workout suggestions."
        />
        <header style={{ backgroundImage: `url(${data.headerImg.publicURL})` }}>
          <h1>
            Calisthenics: The Secret Home At Workout Technique to Kick-start
            your 2021
          </h1>
        </header>
        <section>
          <p className="intro">
            When New Year's resolutions involve fitness, a lot of people tend to
            think that they need to enroll in a gym or invest in expensive gym
            equipment to start working out on a regular basis. However, this is
            not entirely true. So my question to you is:{" "}
            <span className="highlight">
              why not kick-start your 2021 with calisthenics?
            </span>{" "}
            <br />
            Glad you agree! Now you might wonder what exactly calisthenics
            entails, and how can you benefit from working out on a regular
            basis? I'm glad you asked, lets go over some of the basics.
          </p>
          <h2>What Does Calisthenics Involve?</h2>
          <p>
            Even if you do not know what calisthenics is, chances are that you
            have practiced it or seen it in action at some point in your life.
            Simply put, calisthenics is a form of physical exercise that uses
            body weight and gravity leverage to challenge the level of a
            person's fitness. In some cases, small handheld tools, such as wands
            and rings, may be used. The exercises can be performed at varying
            levels of rhythm and intensity, depending on a person's strength,
            endurance, and fitness. Calisthenics is meant to help people improve
            their levels of strength, flexibility, endurance, and coordination,
            and this is why it is common in the fitness training of law
            enforcement officers, athletes, and even military personnel.{" "}
          </p>
          <h3>Benefits</h3>
          <p>
            Working out is all about making movements that put muscles at work
            and cause the body to burn calories. There are many types of
            physical exercises that you can engage in to this end. Below is a
            rundown of the ways in which working out even just a little bit can
            benefit your body and mind.
          </p>
          <h3>Manage your Weight</h3>
          <p>
            In addition to having a healthy diet, working out can go a long way
            in helping you manage weight and prevent obesity. For you to
            maintain a healthy weight, you need to burn as many calories as you
            take and, if you would like to lose weight, you need to burn more
            calories than you take. Therefore, whether you would like to
            maintain or reduce weight, working out will help you to achieve your
            goal.
          </p>
          <h3>Prevent Cardiovascular Problems</h3>
          <p>
            Regular physical exercise is known to strengthen the heart and
            consequently improve blood circulation. Improved circulation has the
            effect of improving oxygen levels in all organs, and this
            significantly lowers the risk of heart disease.
          </p>
          <h3>Manage Stress</h3>
          <p>
            During physical exercise, your body tends to release chemicals that
            make you feel more relaxed and improve your moods. As such, physical
            exercise can help you to manage your stress levels and consequently
            reduce the risk of depression.
          </p>
          <h3>Improve Musculoskeletal Health</h3>
          <p>
            Working out can go a long way when it comes to strengthening bones
            and muscles. This is important because stronger bones and muscles
            can enable you to perform physically demanding tasks more
            efficiently and even avoid some musculoskeletal injuries.
          </p>
          <h3>Improve Your Sleep</h3>
          <p>
            If you are struggling with insomnia, then you need to start working
            out. Since physical exercise relaxes your body and relieves mental
            stress, it can enable you to sleep better.
          </p>
          <h3>How To get Started</h3>
          <p>
            Some examples of calisthenics exercises include pull-ups, chin-ups,
            dips, jump squats, push-ups, crunches, and burpees, among others. As
            such, you don't need any gym equipment to work out. This means that
            you can do it anytime, anywhere, and therefore, you no longer have
            any excuse not to exercise! <br /> While you do not need gym
            equipment to start working out at home, using a power tower or dip
            station in your workout can really boost your fitness efforts. For
            instance, if you feel that you are not strong enough for body weight
            exercises, support bands can help with making your body lighter,
            which will make it easier and safer for you to exercise.
          </p>
        </section>
        <div>
          <h2>Summary</h2>
          <p>
            Overall, it is apparent that working out is one of the most
            important things that you need to do to stay physically and mentally
            healthy. It is also clear that you do not have to invest in
            expensive gym equipment for you to start working out at home.
            Instead, you can make use of your body weight to exercise from
            wherever you are, and this is what calisthenics is all about. But
            although gym equipment is not needed, investing in a dip station or
            power tower can significantly enhance your experience while working
            out. Following a pre-defined exercise program like{" "}
            <a
              target="_blank"
              href="https://97d78chls5-90f0gnn2axeuk4s.hop.clickbank.net/?cbpage=package"
            >
              this
            </a>{" "}
            one can also improve your results, the important thing is to get
            started and you don't need a gym membership or anthing like that to
            get started. Kick-start your 2021 with calisthenics, and be sure to
            enjoy the various benefits that come with staying physically fit.{" "}
          </p>
        </div>
      </Article>
    </Layout>
  )
}

export default KickStart2021FirstArticle
